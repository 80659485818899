import React from "react"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import defaultImage from "../images/defaultTeam.png"

const useStyles = theme => ({
  root: {
    width: "100%",
  },
  heading: {
    fontFamily: "Montserrat !important",
    fontDisplay: "swap",
    fontSize: "18px",
    color: "#000",
  },
  image: {
    width: "100%",
    height: "auto",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    maxWidth: "100%",
    maxHeight: "400px",
    objectFit: "contain",
  },
})

const CoachCard = ({ image, name, ruolo, annoNascita }) => {
  const classes = useStyles()

  return (
    <Grid container spacing={0}>
      <Grid item xs={12}>
        {image !== null && image !== undefined ? (
          <img
            alt={image.title}
            src={image.fluid.src}
            className={classes.image}
            style={{
              borderTopLeftRadius: "20px",
              borderTopRightRadius: "20px",
              objectFit: "contain",
              width: "100%",
            }}
          />
        ) : (
          <img src={defaultImage} alt="default" />
        )}
      </Grid>
      <Grid item xs={12} style={{ marginTop: "-30px" }}>
        <Paper>
          <Grid item xs={12} style={{ paddingLeft: 10, paddingRight: 10 }}>
            <h3
              className={classes.heading}
              style={{ fontFamily: "Montserrat", fontDisplay: "swap" }}
            >
              {name !== null ? name : ""}
            </h3>
          </Grid>
          {ruolo !== null && ruolo !== undefined ? (
            <Grid
              item
              xs={12}
              style={{ marginTop: "-20px", paddingLeft: 10, paddingRight: 10 }}
            >
              <p className={classes.details}>
                Ruolo <span style={{ fontWeight: "bold" }}>{ruolo}</span>
              </p>
            </Grid>
          ) : (
            <></>
          )}
          {annoNascita !== null && annoNascita !== undefined ? (
            <Grid
              item
              xs={12}
              style={{ marginTop: "-20px", paddingLeft: 10, paddingRight: 10 }}
            >
              <p className={classes.details}>
                Anno nascita{" "}
                <span style={{ fontWeight: "bold" }}>{annoNascita}</span>
              </p>
            </Grid>
          ) : (
            <></>
          )}
        </Paper>
      </Grid>
    </Grid>
  )
}

export default CoachCard
