import React from "react"
import { makeStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Accordion from "@material-ui/core/Accordion"
import AccordionDetails from "@material-ui/core/AccordionDetails"
import AccordionSummary from "@material-ui/core/AccordionSummary"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import defaultImage from "../images/defaultTeam.png"

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  heading: {
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontWeight: 800,
    fontSize: "25px",
    flexBasis: "33.33%",
    flexShrink: 0,
  },
  numero: {
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontWeight: 800,
    fontSize: "20px",
    flexBasis: "33.33%",
    flexShrink: 0,
    color: "#305aed",
  },
  image: {
    width: "100%",
    height: "auto",
    borderTopLeftRadius: "20px",
    borderTopRightRadius: "20px",
    maxWidth: "100%",
    maxHeight: "400px",
    objectFit: "contain",
  },
  details: {
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontSize: "18px",
  },
}))

const PlayerCard = ({
  image,
  nome,
  numeroMaglia,
  ruolo,
  annoNascita,
  punti,
  presenze,
}) => {
  const classes = useStyles()
  const [expanded, setExpanded] = React.useState(false)

  const canExpand =
    (annoNascita !== null && annoNascita !== undefined) ||
    (punti !== null && punti !== undefined) ||
    (presenze !== null && presenze !== undefined)

  const handleChange = panel => (event, isExpanded) => {
    if (canExpand) {
      setExpanded(isExpanded ? panel : false)
    }
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        {image !== null && image !== undefined ? (
          <img
            alt={image.title}
            src={image.fluid.src}
            className={classes.image}
          />
        ) : (
          <img src={defaultImage} alt="default" />
        )}
      </Grid>
      <Grid item xs={12} style={{ marginTop: "-33px" }}>
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            expandIcon={
              (annoNascita !== null && annoNascita !== undefined) ||
              (punti !== null && punti !== undefined) ||
              (presenze !== null && presenze !== undefined) ? (
                <ExpandMoreIcon fontSize="large" style={{ color: "#f2545b" }} />
              ) : (
                <></>
              )
            }
            aria-controls="panel1bh-content"
            id="panel1bh-header"
          >
            <Grid container>
              <Grid item xs={12}>
                <Typography className={classes.heading}>
                  {nome !== null ? nome : ""}
                </Typography>
              </Grid>
              {numeroMaglia !== null && numeroMaglia !== undefined ? (
                <Grid item xs={12}>
                  <Typography className={classes.numero}>
                    #{numeroMaglia}
                  </Typography>
                </Grid>
              ) : (
                <></>
              )}
            </Grid>
          </AccordionSummary>
          {(ruolo !== null && ruolo !== undefined) ||
          (annoNascita !== null && annoNascita !== undefined) ||
          (punti !== null && punti !== undefined) ||
          (presenze !== null && presenze !== undefined) ? (
            <AccordionDetails>
              <Grid container>
                {ruolo !== null && ruolo !== undefined ? (
                  <Grid item xs={12}>
                    <Typography className={classes.details}>
                      Ruolo <span style={{ fontWeight: "bold" }}>{ruolo}</span>
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
                {annoNascita !== null && annoNascita !== undefined ? (
                  <Grid item xs={12}>
                    <Typography className={classes.details}>
                      Anno nascita{" "}
                      <span style={{ fontWeight: "bold" }}>{annoNascita}</span>
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
                {punti !== null && punti !== undefined ? (
                  <Grid item xs={12}>
                    <Typography className={classes.details}>
                      Punti <span style={{ fontWeight: "bold" }}>{punti}</span>
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
                {presenze !== null && presenze !== undefined ? (
                  <Grid item xs={12}>
                    <Typography className={classes.details}>
                      Presenze{" "}
                      <span style={{ fontWeight: "bold" }}>{presenze}</span>
                    </Typography>
                  </Grid>
                ) : (
                  <></>
                )}
              </Grid>
            </AccordionDetails>
          ) : (
            <></>
          )}
        </Accordion>
      </Grid>
    </Grid>
  )
}

export default PlayerCard
