import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { makeStyles, withStyles } from "@material-ui/core/styles"
import Layout from "../components/layout"
import Grid from "@material-ui/core/Grid"
import Container from "@material-ui/core/Container"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import PlayerCard from "../components/playerCard"
import CoachCard from "../components/coachCard"
import defaultImage from "../images/defaultTeam.png"
import {
  FacebookShareButton,
  FacebookIcon,
  WhatsappShareButton,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
} from "react-share"

const useStyles = makeStyles(theme => ({
  root: {},
  banner: {
    overflow: "visible",
    marginTop: "-140px",
    paddingTop: "100px",
    backgroundColor: "#0a2540",
    transform: "skew(0deg, -6deg)",
  },
  bannerContent: {
    paddingTop: "150px",
    transform: "skew(0deg, 6deg)",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "80px",
    },
  },
  squadra: {
    paddingTop: "65px",
    paddingBottom: "45px",
  },
  tabsMenu: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  titolo: {
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontSize: "2rem",
    fontWeight: 800,
    color: "#f2545b",
  },
}))

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  }
}

function TabPanel(props) {
  const { children, value, index, ...other } = props

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  )
}
TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
}

const StyledTabs = withStyles({
  indicator: {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
    "& > span": {
      maxWidth: 40,
      width: "100%",
      backgroundColor: "#635ee7",
    },
  },
})(props => (
  <Tabs
    orientation="vertical"
    variant="scrollable"
    {...props}
    TabIndicatorProps={{ children: <span /> }}
  />
))

const StyledTab = withStyles(theme => ({
  root: {
    textTransform: "none",
    color: "#000",
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    fontFamily: "Montserrat",
    fontDisplay: "swap",
    fontWeight: "800",
    transition: theme.transitions.create(
      ["background-color", "color", "opacity"],
      { duration: "500ms" }
    ),
    "&:focus": {
      opacity: 1,
      color: "#fff",
      backgroundColor: "#305aed",
    },
    "&:hover": {
      border: "1px solid #305aed",
    },
  },
}))(props => <Tab disableRipple {...props} />)

const Team = ({ data: { team }, location }) => {
  const classes = useStyles()
  const [value, setValue] = React.useState(0)
  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  var labels = [{ nome: "Roster", index: 0 }]
  var lastIndex = 0
  if (
    "attivitaGiovanile" in team &&
    team.attivitaGiovanile !== null &&
    team.attivitaGiovanile !== undefined
  ) {
    lastIndex += 1
    labels.push({ nome: "Attività Giovanile", index: lastIndex })
  }
  if (
    "galleria" in team &&
    team.galleria !== null &&
    team.galleria !== undefined
  ) {
    lastIndex += 1
    labels.push({ nome: "Galleria", index: lastIndex })
  }
  if (
    "statistica" in team &&
    team.statistica !== null &&
    team.statistica !== undefined
  ) {
    lastIndex += 1
    labels.push({ nome: "Statistica", index: lastIndex })
  }
  if (
    "rassegnaStampa" in team &&
    team.rassegnaStampa !== null &&
    team.rassegnaStampa !== undefined
  ) {
    lastIndex += 1
    labels.push({ nome: "Rassegna Stampa", index: lastIndex })
  }
  function getIndex(nome) {
    var index = -1
    labels.forEach(label => (label.nome === nome ? (index = label.index) : ""))
    return index
  }

  var nomeSquadra = team.nome.slice(0, -5)
  var anno = parseInt(team.nome.slice(-4))
  var annoP = (anno + 1).toString().slice(-2)
  var annoSportivo = "Anno Sportivo " + anno.toString() + "/" + annoP
  const url = location.href ? location.href : ""

  return (
    <Layout
      title={team.nome}
      image={team.foto ? team.foto.fluid.src : defaultImage}
      description="Squadra dello Jesolo San Donà Basket"
    >
      <div className={classes.root}>
        <section className={classes.banner}>
          <Container className={classes.bannerContent}>
            <Grid container>
              <Grid item xs={12} sm={6} container>
                <Grid item xs={12}>
                  <h1
                    style={{
                      color: "#fe5f55",
                      fontFamily: "Montserrat",
                      fontDisplay: "swap",
                    }}
                  >
                    {nomeSquadra}
                  </h1>
                  <h3
                    style={{
                      color: "#fff",
                      fontFamily: "Montserrat",
                      fontDisplay: "swap",
                    }}
                  >
                    {annoSportivo}
                  </h3>
                  <div>
                    <p
                      style={{
                        color: "#fff",
                        fontFamily: "Montserrat",
                        fontDisplay: "swap",
                      }}
                    >
                      Condividi su{" "}
                      <FacebookShareButton
                        url={url}
                        hashtag="#jsdbasket"
                        quote="Guardate cosa ho trovato tra gli articoli del Jesolo San Donà Basket"
                      >
                        <FacebookIcon size={40} round={true} />
                      </FacebookShareButton>
                      <WhatsappShareButton url={url} title={team.nome}>
                        <WhatsappIcon size={40} round={true} />
                      </WhatsappShareButton>
                      <EmailShareButton
                        url={url}
                        subject="Guarda cosa ho trovato tra gli articoli del Jesolo San Donà Basket"
                      >
                        <EmailIcon size={40} round={true} />
                      </EmailShareButton>
                    </p>
                  </div>
                </Grid>
              </Grid>
              <Grid item xs={12} sm={6}>
                {team.foto !== null && team.foto !== undefined ? (
                  <img
                    alt={team.foto.title}
                    src={team.foto.fluid.src}
                    className={classes.hero}
                    style={{ marginBottom: 40 }}
                  />
                ) : (
                  ""
                )}
              </Grid>
            </Grid>
          </Container>
        </section>
        <section className={classes.squadra}>
          <Container>
            <Grid container spacing={3}>
              <Grid item xs={4} sm={4}>
                <h4
                  style={{
                    color: "#305aed",
                    fontFamily: "Montserrat",
                    fontDisplay: "swap",
                  }}
                >
                  Seleziona alternativa
                </h4>
                <StyledTabs
                  value={value}
                  onChange={handleChange}
                  aria-label="Squadre dei periodi"
                  className={classes.tabsMenu}
                >
                  {labels.map(label => (
                    <StyledTab
                      label={label.nome}
                      key={label.index}
                      {...a11yProps(label.index)}
                    />
                  ))}
                </StyledTabs>
              </Grid>
              <Grid item xs={8} sm={8}>
                <TabPanel value={value} index={getIndex("Roster")} id="roster">
                  <Container>
                    {(team.allenatore !== null &&
                      team.allenatore !== undefined) ||
                    (team.viceAllenatori !== null &&
                      team.viceAllenatori !== undefined) ? (
                      <h2 className={classes.titolo}>Allenatori</h2>
                    ) : (
                      <></>
                    )}
                    <Grid container spacing={3}>
                      <Grid item xs={12} container spacing={3}>
                        {team.allenatore !== null &&
                        team.allenatore !== undefined ? (
                          <Grid item xs={12} sm={6} md={4}>
                            <CoachCard
                              image={team.allenatore.foto}
                              name={team.allenatore.persona.nome}
                              ruolo={team.allenatore.ruolo}
                              annoNascita={team.allenatore.persona.annoNascita}
                            />
                          </Grid>
                        ) : (
                          <Grid item xs={12} sm={6} md={4} />
                        )}
                        {team.viceAllenatori !== null &&
                        team.viceAllenatori !== undefined ? (
                          team.viceAllenatori.map((allenatore, index) => (
                            <Grid item xs={12} sm={6} md={4} key={index}>
                              <CoachCard
                                image={allenatore.foto}
                                name={allenatore.persona.nome}
                                ruolo={allenatore.ruolo}
                                annoNascita={allenatore.persona.annoNascita}
                              />
                            </Grid>
                          ))
                        ) : (
                          <Grid item xs={12} sm={6} md={4} />
                        )}
                      </Grid>
                      <Grid item xs={12} container>
                        {team.assistenti !== null &&
                        team.assistenti !== undefined ? (
                          <>
                            <Grid item xs={12}>
                              <h2 className={classes.titolo}>Assistenti</h2>
                            </Grid>
                            <Grid item xs={12} container spacing={3}>
                              {team.assistenti.map((assistente, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                  <CoachCard
                                    image={assistente.foto}
                                    name={assistente.persona.nome}
                                    ruolo={assistente.ruolo}
                                    annoNascita={assistente.persona.annoNascita}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </>
                        ) : (
                          <Grid item xs={12} sm={6} md={4} />
                        )}
                      </Grid>
                      <Grid item xs={12} container>
                        {team.management !== null &&
                        team.management !== undefined ? (
                          <>
                            <Grid item xs={12}>
                              <h2 className={classes.titolo}>Società</h2>
                            </Grid>
                            <Grid item xs={12} container spacing={3}>
                              {team.management.map((manager, index) => (
                                <Grid item xs={12} sm={6} md={4} key={index}>
                                  <CoachCard
                                    image={manager.foto}
                                    name={manager.persona.nome}
                                    ruolo={manager.ruolo}
                                    annoNascita={manager.persona.annoNascita}
                                  />
                                </Grid>
                              ))}
                            </Grid>
                          </>
                        ) : (
                          <Grid item xs={12} sm={6} md={4} />
                        )}
                      </Grid>
                    </Grid>
                    <h2 className={classes.titolo}>Giocatori</h2>
                    <Grid container spacing={3}>
                      {team.giocatori.map((giocatore, index) => (
                        <Grid item xs={12} sm={6} md={4} key={index}>
                          <PlayerCard
                            image={giocatore.foto}
                            nome={giocatore.persona.nome}
                            numeroMaglia={giocatore.numeroMaglia}
                            ruolo={giocatore.ruolo}
                            annoNascita={giocatore.persona.annoNascita}
                            punti={giocatore.punti}
                            presenze={giocatore.presenze}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Container>
                </TabPanel>
                {"attivitaGiovanile" in team &&
                team.attivitaGiovanile !== null &&
                team.attivitaGiovanile !== undefined ? (
                  <TabPanel
                    value={value}
                    index={getIndex("Attività Giovanile")}
                    id="attivita_giovanile"
                  >
                    <h2 color="primary">Attività Giovanile</h2>
                    {team.attivitaGiovanile.map((attivita, index) => (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={attivita.file.url}
                        key={index}
                      >
                        Download Attività Giovanile
                      </a>
                    ))}
                  </TabPanel>
                ) : (
                  <></>
                )}
                {"galleria" in team &&
                team.galleria !== null &&
                team.galleria !== undefined ? (
                  <TabPanel
                    value={value}
                    index={getIndex("Galleria")}
                    id="galleria"
                  >
                    <h2 color="primary">Foto</h2>
                    <Grid container spacing={3}>
                      {team.galleria.map((foto, index) => (
                        <Grid item container md={4} sm={6} xs={12} key={index}>
                          <Grid item xs={12}>
                            <img
                              alt={foto.title}
                              src={foto.fluid.src}
                              style={{
                                borderRadius: "20px",
                                objectFit: "contain",
                                width: "100%",
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <p>{foto.title}</p>
                          </Grid>
                        </Grid>
                      ))}
                    </Grid>
                  </TabPanel>
                ) : (
                  <></>
                )}
                {"statistica" in team &&
                team.statistica !== null &&
                team.statistica !== undefined ? (
                  <TabPanel
                    value={value}
                    index={getIndex("Statistica")}
                    id="statistica"
                  >
                    <h2 color="primary">Statistica</h2>
                    <a
                      target="_blank"
                      rel="noreferrer"
                      href={team.statistica.file.url}
                    >
                      Download Statistica
                    </a>
                  </TabPanel>
                ) : (
                  <></>
                )}
                {"rassegnaStampa" in team &&
                team.rassegnaStampa !== null &&
                team.rassegnaStampa !== undefined ? (
                  <TabPanel
                    value={value}
                    index={getIndex("Rassegna Stampa")}
                    id="rassegna_stampa"
                  >
                    <h2 color="primary">Rassegna stampa</h2>
                    {team.rassegnaStampa.map((stampa, index) => (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={stampa.file.url}
                        key={index}
                      >
                        Download Rassegna Stampa
                      </a>
                    ))}
                  </TabPanel>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Container>
        </section>
      </div>
    </Layout>
  )
}

export default Team

export const query = graphql`
  query($slug: String!) {
    team: contentfulSquadra(node_locale: { eq: "it-IT" }, slug: { eq: $slug }) {
      nome
      foto {
        title
        fluid(quality: 100) {
          src
        }
      }
      anno {
        anno
      }
      allenatore {
        nome
        ruolo
        foto {
          title
          fluid(quality: 100) {
            src
          }
        }
        persona {
          nome
          annoNascita
        }
      }
      viceAllenatori {
        nome
        ruolo
        foto {
          title
          fluid(quality: 100) {
            src
          }
        }
        persona {
          nome
          annoNascita
        }
      }
      assistenti {
        nome
        ruolo
        foto {
          title
          fluid(quality: 100) {
            src
          }
        }
        persona {
          nome
          annoNascita
        }
      }
      management {
        nome
        ruolo
        foto {
          title
          fluid(quality: 100) {
            src
          }
        }
        persona {
          nome
          annoNascita
        }
      }
      giocatori {
        nome
        punti
        presenze
        numeroMaglia
        foto {
          title
          fluid(quality: 100) {
            src
          }
        }
        persona {
          nome
          annoNascita
        }
      }
      campionato
      galleria {
        title
        description
        fluid(quality: 100) {
          src
        }
      }
      attivitaGiovanile {
        title
        description
        file {
          url
        }
      }
      statistica {
        title
        description
        file {
          url
        }
      }
      rassegnaStampa {
        title
        description
        file {
          url
        }
      }
    }
  }
`
